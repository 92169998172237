<script>
const d = document
const cName = 'getElementsByClassName'
const iName = 'getElementById'
const HTML = `<!-- 播放结束后的按钮 -->
  <div class="btns">
    <div class="btnItem btnItem1">
      <div class="icon">
        <img src="${require('@/assets/image/curriculum/20220510-150530.png')}" alt="">
      </div>
      <div class="btnName">再次学习</div>
    </div>
    <!-- 是否能小节自测1.是 0.否 -->
    <div class="btnItem btnItem2">
      <div class="icon">
        <img src="${require('@/assets/image/curriculum/20220510-150536.png')}" alt="">
      </div>
      <div class="btnName">小节自测</div>
    </div>
    <!-- 章节自测状态1.未开始 2.未看完 3.已看完 4.自测通过 5.自测失败 -->
    <div class="btnItem btnItem3" @click="setVodCourse()">
      <div class="icon">
        <img src="${require('@/assets/image/curriculum/20220510-150525.png')}" alt="">
      </div>
      <div class="btnName">学习下节课程</div>
    </div>
    <div class="btnItem btnItem4" style="pointer-events: none;">
      <div class="icon">
        <img src="${require('@/assets/image/curriculum/20220510-150525.png')}" alt="">
      </div>
    </div>
    <div class="btnItem btnItem5">
      <div class="icon">
        <img src="${require('@/assets/image/curriculum/20220923-150525.png')}" alt="">
      </div>
      <div class="btnName">立即购买</div>
    </div>
  </div>`
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {
    // 插入按钮html
    addPlayerBtnHtml(){
      this.$nextTick(()=>{
        let videoContent = document.createElement('div')
        videoContent.className = 'video-content'
        videoContent.innerHTML = HTML
        d[iName]('player-container-id').appendChild(videoContent)
        this.$nextTick(()=>{
          // 再次学习
          d[cName]('btnItem1')[0].onclick = ()=>{
            this.hidePlayerBtn()
            this.onReplay()
          }
          // 小节自测
          d[cName]('btnItem2')[0].onclick = ()=>{
            // this.hidePlayerBtn()
            this.$router.push(`/examination/selfTest?courseName=${this.courseDetail.courseName}&courseId=${this.$AES.encode_data(String(this.videoConfig.courseId))}&sectionId=${this.$AES.encode_data(String(this.videoConfig.sectionId))}&sectionName=${this.videoConfig.sectionName}`)
          }
          // 学习下节课程
          d[cName]('btnItem3')[0].onclick = ()=>{
            this.hidePlayerBtn()
            this.setVodCourse()
          }
          // 立即购买
          d[cName]('btnItem5')[0].onclick = ()=>{
            this.$router.go(-1)
          }
        })
      })
    },

    // 按钮显示判断
    showPlayerBtn(){
      // 打开按钮页面，显示对应的功能按钮
      this.$nextTick(()=>{
        d[cName]('video-content')[0].style.display = 'block'
        if(this.ended){ // 结束观看
          d[cName]('btnItem1')[0].style.display = 'block' // 再次学习

          // 已购买
          if(this.isHave == 1){
            if(this.videoConfig.isSelfTest==1){
              d[cName]('btnItem2')[0].style.display = 'block' // 小节自测
            }
            if((this.videoConfig.passStatus==4 || (this.videoConfig.passStatus==3 && this.videoConfig.isSelfTest == 0)) && this.videoConfig.nextSectionId){
              d[cName]('btnItem3')[0].style.display = 'block' // 学习下一节
            }
          } else {  // 未购买试看
            d[cName]('btnItem5')[0].style.display = 'block' // 立即购买
          }

          d[cName]('btnItem4')[0].style.display = 'none'

        }else{ // 暂停观看
          let doc = d[cName]('btnItem')
          for(let i=0;i<doc.length;i++){
            doc[i].style.display = 'none'
          }
          d[cName]('btnItem4')[0].style.display = 'block'
        }
      })
    },

    // 按钮隐藏判断
    hidePlayerBtn(){
      // 隐藏按钮页面
      if(d[cName]('video-content')[0]){
        d[cName]('video-content')[0].style.display = 'none'
      }
    },

    // 插入切换下一集按钮
    addPlayerBtnAfter(){
      if(d[cName]('vjs-next')[0]){
        if(!this.videoConfig.nextSectionId){
          d[cName]('vjs-next')[0].style.display = 'none'
        }else{
          d[cName]('vjs-next')[0].style.display = 'block'
        }
        return false
      }else if(!this.videoConfig.nextSectionId){
        return false
      }
      const btnHtml = `<button class="vjs-control vjs-button vjs-next" type="button" aria-live="polite" title="下一集" aria-disabled="false">
        <img src="${require('@/assets/image/curriculum/nextVideo.png')}" alt="">
      </button>`
      d[cName]('vjs-play-control')[0].insertAdjacentHTML('afterEnd',btnHtml)
      this.$nextTick(()=>{
        // 点击下一集
        d[cName]('vjs-next')[0].onclick = ()=>{
          this.sectionId = this.videoConfig.nextSectionId
          this.getVod()
        }
      })
    },

    // 插入切换上一集按钮
    addPlayerBtnBefore(){
      if(d[cName]('vjs-previous')[0]){
        if(!this.videoConfig.previousSectionId){
          d[cName]('vjs-previous')[0].style.display = 'none'
        }else{
          d[cName]('vjs-previous')[0].style.display = 'block'
        }
        return false
      }else if(!this.videoConfig.previousSectionId){
        return false
      }
      const btnHtml = `<button class="vjs-control vjs-button vjs-previous" type="button" aria-live="polite" title="上一集" aria-disabled="false">
        <img src="${require('@/assets/image/curriculum/previousVideo.png')}" alt="">
      </button>`
      d[cName]('vjs-play-control')[0].insertAdjacentHTML('beforeBegin',btnHtml)
      this.$nextTick(()=>{
        // 点击上一集
        d[cName]('vjs-previous')[0].onclick = ()=>{
          this.sectionId = this.videoConfig.previousSectionId
          this.getVod()
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>
