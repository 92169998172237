<template>
  <div>
    <div class="commenttabs">
      <p @click="changeCommentIndex(index)" class="tabItem" :class="commentIndex == index?'commentactive':''" v-for="(item,index) in evaluateList" :key="index">{{item}}</p>
    </div>
    <div class="commentList">
      <div class="commitItem" v-for="(item,index) in evaluationList" :key="index">
        <div class="list_top">
          <img class="top_photo" v-if="item.userIcon" :src="item.userIcon" alt="">
          <img v-else class="top_photo" src="@/assets/image/default_photo.png" />
          <div class="userMess">
            <div>
              <span class="top_phone">{{item.nickName}}</span>
              <img class="top_flower" v-for="i in item.evaluation" :key="i + '-' + item.evaluationId" src="@/assets/image/order/flower-active.png" alt="好评">
            </div>
            <div class="con_data">{{timeFormatSeconds(item.createTime.split("-").join('/'))}}</div>
          </div>
          
          <!-- <img class="top_flower" v-for="j in (5 - item.evaluation)" :key="j + '+' + item.evaluationId" src="@/assets/image/icon/good1_icon.png" alt="好评"> -->
        </div>
        <div class="list_con">
          <div class="con_main">{{item.comment?item.comment:'此用户没有填写评价'}}</div>
        </div>
        <div class="list_reply" v-show="item.isReply">
          <span class="reply_main">小编回复：</span>
          <span>{{item.reply}}</span>
        </div>
      </div>
      <a-spin class="load" v-show="loading" size='large' />
      <no-message style="margin-top: 50px;" :msg="'暂无数据'" v-if="!evaluationList.length" type=1></no-message>
    </div>
  </div>
</template>

<script>
export default {
  props: ['pruductId'],
  data() {
    return {
      loading: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      evaluation: '', // 请求所需分类id
      evaluationList: [], // 评价列表
      commentIndex: 0, // 评价tabIndex
      evaluateList: [], // 评价头部中好评菜单
    }
  },
  created(){
    // 获取评价数量
    this.getAppraiseCount(this.pruductId);
  },
  methods:{
    // 查询评价数量
		getAppraiseCount(e){
      this.$ajax({
        url: "/hxclass-pc/order-comment/count",
        params: {
					productId: e,
				}
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if(!!res.data.all){
            this.getCommentList();
          }
					this.evaluateList = [
						'全部(' + res.data.all + ')',
						'好评(' + res.data.good + ')',
						'中评(' + res.data.medium + ')',
						'差评(' + res.data.bad + ')',
					]
        }
      });
		},
		// 评价头部菜单切换
    changeCommentIndex(e){
      this.commentIndex = e;
      this.pageNum = 1;
			if (e==1) {
				this.evaluation = 3
			} else if (e==2) {
				this.evaluation = 2
			} else if (e==3) {
				this.evaluation = 1
			} else if (e==0) {
				this.evaluation = ''
			}
      this.loading = true;
			this.getCommentList();
		},
    // 获取评价列表
		getCommentList(){
			this.$ajax({
				url:'/hxclass-pc/order-comment/',
				method:'get',
				params:{
          page:this.pageNum,
          size:100000,
          productId:this.pruductId,
					evaluation:this.evaluation
        }
			}).then((res) => {
        if (res.code == 200 && res.success) {
          this.loading = false;
					this.evaluationList = res.data.records
					// 列表新内容添加后，把最小高度更新
					this.$nextTick(()=>{
						if(!document.getElementsByClassName('tabsContent')[0]){
							return false
						}
					})
        }
      });
		},
		timeFormatSeconds(time){
			//传入日期//例：2020-10-27T14:36:23
			var d = time ? new Date((time).replace(/-/g,'/')) : new Date();
			var year = d.getFullYear();
			var month = d.getMonth() + 1;
			var day = d.getDate();
			var hours = d.getHours();
			var min = d.getMinutes();
			var seconds = d.getSeconds();
	
			if (month < 10) month = '0' + month;
			if (day < 10) day = '0' + day;
			if (hours < 0) hours = '0' + hours;
			if (min < 10) min = '0' + min;
			if (seconds < 10) seconds = '0' + seconds;
			return (year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + seconds);
		},
  }
}
</script>

<style scoped lang="less">
/* 整个滚动条 */
::-webkit-scrollbar {
    /* 对应纵向滚动条的宽度 */
    width: 10px;
    /* 对应横向滚动条的宽度 */
    height: 10px;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
    background-color: #F0F0F0;
    border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
    background-color: #F9F9F9;
    border-radius: 32px;
}

.load{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.commentList{
  max-height: 500px;
  overflow: auto;
}
.commenttabs{
  display: flex;
  margin: 11px 40px 36px;
  padding-bottom: 20px;
  border-bottom: 1px solid #F2F2F2;
  .tabItem{
    font-size: 18px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 27px;
    margin-right: 40px;
    cursor: pointer;
  }
  .commentactive{
    font-weight: 500;
    color: #15B7DD;
  }
}
.commitItem{
  padding-left: 40px;
  margin-bottom: 40px;
}
.list_top {
  display: flex;
  align-items: center;
  .top_photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .top_phone {
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .top_flower {
    width: 13px;
    margin-left: 8px;
  }
  .con_data {
    font-size: 14px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 21px;
    margin-left: 16px;
  }
  .userMess{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
.list_con {
  padding-left: 62px;
  margin-top: 16px;
  .con_main {
    font-size: 16px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }
}
.list_reply {
  font-size: 16px;
  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  padding: 14px 20px;
  background: rgba(0,0,0,0.04);
  border-radius: 2px;
  margin-left: 62px;
  margin-top: 16px;
  // .reply_main {
  //   color: #333333;
  // }
}
</style>